import { useMediaQuery, Popper, Fade, ClickAwayListener, CSSObject, Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Theme } from '@mui/material/styles'
import useTheme from '@mui/styles/useTheme'
import React, { FC, ReactNode, useRef } from 'react'
import MapButton from './MapButton'
import MapDialogMobile from '../dialogs/MapDialogMobile'
import { translucentPaperBackgroundColor } from '../utils/colors'

const useStyles = makeStyles((theme: Theme) => {
  const arrowBase = {
    position: 'absolute',
    zIndex: theme.zIndex.modal + 1,
    backgroundColor: translucentPaperBackgroundColor({ elevation: 24, opacity: 0.85, theme }),
    backdropFilter: 'blur(5px)',
  } satisfies CSSObject

  return {
    leftPopper: {
      marginLeft: '24px !important',
    },
    leftArrow: {
      ...arrowBase,
      width: 8,
      height: 12,
      left: 64,
      bottom: 18,
      clipPath: 'polygon(100% 0, 0 50%, 100% 100%)'
    },
    topPopper: {
      marginTop: '24px !important',
    },
    topArrow: {
      ...arrowBase,
      width: 12,
      height: 8,
      top: 72,
      left: 18,
      clipPath: 'polygon(0 100%, 100% 100%, 50% 0)'
    },
    bottomArrow: {
      ...arrowBase,
      width: 12,
      height: 8,
      bottom: 64,
      left: 18,
      clipPath: 'polygon(0 0, 50% 100%, 100% 0)'
    },
    rightPopper: {
      marginRight: '24px !important',
    },
    rightArrow: {
      ...arrowBase,
      width: 8,
      height: 12,
      right: 64,
      bottom: 18,
      clipPath: 'polygon(0 0, 0 100%, 100% 50%)'
    },
    bottomPopper: {
      marginBottom: '24px !important',
    },
  }
})

type MapButtonPanelRegion = 'bottom' | 'left' | 'top' | 'right'
export const MapPopperButton: FC<
  React.PropsWithChildren<{
    region?: MapButtonPanelRegion
    icon: React.ReactNode
    content?: React.ReactElement
    mobileContent?: React.ReactElement
    mobileTitle?: React.ReactNode
    open?: boolean
    onClose: () => void
    title?: ReactNode
    tooltipText?: string
  }>
> = ({ region, icon, content, mobileContent, open, onClose, title, tooltipText, mobileTitle }) => {
  const buttonRef = useRef(null)
  const classes = useStyles()
  const theme = useTheme() as Theme
  const isMobilOrTablet = useMediaQuery(theme.breakpoints.down('sm'))
  const placement =
    region === 'left'
      ? 'right-end'
      : region === 'right'
      ? 'left-end'
      : region === 'bottom'
      ? 'top-start'
      : 'bottom-start'
  const renderMobile = () =>
    mobileContent && open ? (
      <Box position="fixed" bottom={0} left={0} width="100vw" height="100vh" zIndex={1300}>
        <MapDialogMobile onClose={onClose} title={mobileTitle || title}>
          {mobileContent}
        </MapDialogMobile>
      </Box>
    ) : null
  const renderContent = () => {
    if (content && open) {
      return (
        <Popper
          sx={{ zIndex: (theme) => theme.zIndex.modal + 1, paddingTop: 1 }}
          open={!!open}
          anchorEl={buttonRef.current}
          transition
          autoFocus
          className={classes[(region + 'Popper') as keyof typeof classes]}
          placement={placement}
          modifiers={[
          {
            name: 'preventOverflow',
            options: {
              mainAxis: false,
            },
          },
        ]}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps}>
              <Box flexDirection="row">
                <ClickAwayListener
                  onClickAway={(event) => {
                    // Dirty hack to solve issue with mouseevent having to be onMouseUp in clickaway to solve problem with select-clicking triggering clickaway
                    setTimeout(() => onClose())
                  }}
                  mouseEvent="onMouseUp"
                >
                  {content}
                </ClickAwayListener>
              </Box>
            </Fade>
          )}
        </Popper>
      )
    }
  }

  const tooltipDirection =
    region === 'left' ? 'right' : region === 'right' ? 'left' : region === 'bottom' ? 'top' : 'bottom'
  return (
    <>
      <Box position="relative">
        <MapButton
          onClick={onClose}
          icon={icon}
          ref={buttonRef}
          isActive={open}
          toolTipText={open ? undefined : tooltipText}
          toolTipPlacement={tooltipDirection}
        />
        <div
          className={classes[(region + 'Arrow') as keyof typeof classes]}
          style={{ display: open ? 'block' : 'none' }}
        />
      </Box>
      {isMobilOrTablet ? renderMobile() : renderContent()}
    </>
  )
}
